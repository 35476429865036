import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: ${props => props.flex};
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 24px;
  position: relative;
  @media (max-width: 512px) {
    padding: 0 12px;
  }
`

const Container = ({ children, flex = '0 1 auto' }) => (
  <StyledContainer flex={flex}>
    {children}
  </StyledContainer>
)

export default Container