import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithubAlt,
  faMediumM,
  faRedditAlien,
  faTelegramPlane,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

import Container from '../Container'
import { black } from '../../colors'

import Logo from '../Logo'

const StyledFooter = styled.footer`
    background-color: ${black[900]};
    color: rgba(255,255,255,0.8);
`

const StyledFooterInner = styled.div`
    color: #FFF;
    padding: 128px 0;
`

const StyledSocialWrapper = styled.div`
    display: flex;
    @media (max-width: 512px) {
        margin-top: 48px;
    }
`

const StyledSocialIcon = styled.a`
    align-items: center;
    display: flex;
    font-size: 24px;
    height: 44px;
    justify-content: center;
    width: 44px;
    color: #FFF;
    cursor: pointer;
    margin: 0 6px;
    opacity: 0.8;
    text-decoration: none;
    &:hover {
        opacity: 1;
    }
`

const StyledWrapper = styled.div`
    display: flex;
    @media (max-width: 512px) {
        flex-direction: column;
        align-items: center;
    }
`

const Footer = () => (
  <StyledFooter>
    <Container>
      <StyledFooterInner>
        <StyledWrapper>
          <div style={{ flex: 1 }}>
            <Logo />
          </div>
          <StyledSocialWrapper>
            <StyledSocialIcon color="#FFF" href="https://github.com/kollateral">
              <FontAwesomeIcon icon={faGithubAlt} />
            </StyledSocialIcon>
            <StyledSocialIcon color="#FFF" href="https://twitter.com/kollateralco">
              <FontAwesomeIcon icon={faTwitter} />
            </StyledSocialIcon>
            <StyledSocialIcon href="https://t.me/kollateralco">
              <FontAwesomeIcon color="#FFF" icon={faTelegramPlane} />
            </StyledSocialIcon>
          </StyledSocialWrapper>
        </StyledWrapper>
        <div style={{ marginTop: 48, opacity: 0.5, fontSize: 14, lineHeight: 1.5 }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <span style={{ padding: 12 }}>{`Copyright 2020 Kollateral LLC`}</span>

            <span style={{ padding: 12 }}>{`Licensed under the Apache License, Version 2.0 (the "License");
              you may not use the included code except in compliance with the License.
              You may obtain a copy of the License at
              http://www.apache.org/licenses/LICENSE-2.0`}</span>

            <span style={{ padding: 12 }}>{`Unless required by applicable law or agreed to in writing, software
              distributed under the License is distributed on an "AS IS" BASIS,
              WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
              See the License for the specific language governing permissions and
              limitations under the License.`}</span>
          </div>
        </div>
      </StyledFooterInner>
    </Container>
  </StyledFooter>
)

export default Footer