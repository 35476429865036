import React from 'react'
import styled from 'styled-components'

import { primary } from '../../colors'

const StyledLogo = styled.span`
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    margin-left: 12px;
    @media (max-width: 512px) {
      font-size: 18px;
    }
`
const StyledSvg = styled.svg`
    height: 32px;
    width: 32px;
    @media (max-width: 512px) {
      height: 24px;
      width: 24px;
    }
`
const Logo = () => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <StyledSvg
      fill={primary[400]}
      viewBox="0 0 212 212"
    >
      <rect x="0" y="0" width="70.667" height="212" />
      <rect x="0" y="70.667" width="141.333" height="70.667" />
      <rect x="141.333" y="141.333" width="70.667" height="70.667" />
      <rect x="141.333" y="0" width="70.667" height="70.667" />
    </StyledSvg>
    <StyledLogo>Kollateral</StyledLogo>
  </div>
)

export default Logo