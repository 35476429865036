import React from 'react'

import styled from 'styled-components'

import Container from '../Container'
import Logo from '../Logo'

import { black, white } from '../../colors'

const StyledHeader = styled.header`
  align-items: center;
  background-color: ${black[900]};
  display: flex;
`

const StyledHeaderInner = styled.div`
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: space-between;
  @media (max-width: 512px) {
    height: 72px;
  }
`

const StyledNav = styled.nav`
  display: flex;
  @media (max-width: 512px) {
  }
`

const StyledLink = styled.a`
  color: ${white[50]};
  font-weight: 500;
  opacity: 0.8;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
`

const StyledMobileMenu = styled.div`
  display: none;
  @media (max-width: 512px) {
    align-items: center;
    color: #FFF;
    justify-content: center;
    height: 44px;
    width: 44px;
  }
`

const Header = () => (
  <StyledHeader>
    <Container>
      <StyledHeaderInner>
        <Logo />
        <StyledNav>
          <StyledLink href="https://docs.kollateral.co">Docs</StyledLink>
        </StyledNav>
      </StyledHeaderInner>
    </Container>
  </StyledHeader>
)

export default Header
